<template>
  <div class="bodyBox">
    <el-container>
      <el-aside>
        <asideItem
          @conversation="conversation"
          @introduceItemShow="introduceItemShow"
          ref="asideItem"
        ></asideItem
      ></el-aside>
      <el-container>
        <!-- <el-header>Header</el-header> -->
        <el-main
          ><mainItem @getGroupList="getGroupList" ref="mainItem"></mainItem
        ></el-main>
        <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>
<script>
import asideItem from "@/components/aside";
import mainItem from "@/components/Main";
export default {
  name: "mainPage",
  components: { asideItem, mainItem },
  data() {
    return {};
  },
  methods: {
    getGroupList() {
      this.$refs.asideItem.getGroupList();
    },
    conversation() {
      this.$refs.mainItem.conversationListShow();
    },
    introduceItemShow() {
      this.$refs.mainItem.uNonversationListShow();
    },
  },
};
</script>

<style>
.bodyBox {
  height: 100vh;
}
.el-header,
.el-footer {
  text-align: center;
}

.el-aside {
  background-color: rgba(32, 33, 35, 1);
  color: white;
  text-align: center;
}

.el-main {
  text-align: center;
}
.el-card__body,
.el-main {
  padding: 0px;
}
body > .el-container {
  margin-bottom: 40px;
}

/* .el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
} */
.el-header,
.el-footer {
  background-color: black;
  color: white;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  text-align: center;
  height: 100vh;
}

.el-main {
  background-color: white;
  color: black;
  text-align: center;
  height: 100vh;
}

body > .el-container {
  margin-bottom: 40px;
}

/* .el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
} */
</style>
