<template>
  <div class="contentBox" ref="mainGroup">
    <!-- id{{ conversationList[1] }} -->
    <div v-for="item in conversationList" :key="item.id">
      <!-- {{ item.answer }} -->
      <dialogueTextItem :text="item.questionMsg" />
      <dialogueCodeTxtItem :code="item.answer" />
    </div>
    <div class="bottomTo" @click="scrollToBottom">
      <i class="el-icon-bottom" style="font-size: 30px"></i>
    </div>
  </div>
</template>
<script>
import { chatListByID } from "@/api/conversation";
import dialogueCodeTxtItem from "@/components/dialogue/codeTxt.vue";
import dialogueTextItem from "@/components/dialogue/text.vue";
import EventSource from "eventsource"; //sse
// import { getToken } from "@/utils/auth.js";
export default {
  name: "contentPage",
  components: { dialogueCodeTxtItem, dialogueTextItem },
  watch: {
    "$route.params.id": function () {
      let id = this.$route.params.id;
      this.conversationId = this.$route.params.id;
      this.conversationList = [];
      this.getconversationContent(id);
    },
  },
  data() {
    return {
      conversationId: 0,
      conversationList: [],
      csHtml: "",
      objModel: {
        answer: "",
        completionTokens: "",
        gmtCreate: "",
        id: "",
        promptTokens: "",
        questionMsg: "",
      },
    };
  },
  created() {
    this.conversationId = this.$route.params.id;
    let id = this.$route.params.id;
    this.getconversationContent(id);
  },
  methods: {
    addItem(value) {
      if (!value) return;
      this.objModel = {
        answer: "",
        completionTokens: "",
        gmtCreate: "",
        id: "",
        promptTokens: "",
        questionMsg: "",
      };
      this.objModel.questionMsg = value;
      this.conversationList.push(this.objModel);
      let index = this.conversationList.length - 1;
      this.$set(this.conversationList, index, this.objModel);
    },
    setupSSE() {
      var vm = this;
      const url = "http://chat.wwsix.cn/endpoint/v1/chat/see";
      let token = JSON.parse(localStorage.getItem("access_token"));
      const headers = {
        Authorization: token,
      };
      const evtSource = new EventSource(
        url,
        { headers }
        // { withCredentials: true } //允许跨域;
      );
      // 与事件源的连接刚打开时触发
      evtSource.onopen = function (e) {
        console.log("onopen", e);
        vm.objModel.answer = "";
      };
      // 当从事件源接收到数据时触发
      evtSource.onmessage = function (e) {
        if (e.lastEventId != "[TOKENS]" && e.lastEventId != "[DONE]") {
          let index = vm.conversationList.length - 1;
          let obj = JSON.parse(e.data);
          vm.objModel.answer += obj.answer;
          //这句要加不然第一次渲染会停止
          vm.$set(vm.conversationList, index, vm.objModel);
          vm.scrollToBottom();
          // console.log("onmessage", vm.conversationList, vm.objModel);
        }
      };
      // 与事件源的连接无法打开时触发
      evtSource.onerror = function (e) {
        // vm.objModel.answer = "";
        vm.$emit("closeOnload");
        console.log(e);
        evtSource.close(); // 关闭连接
      };
    },
    getconversationContent(id) {
      if (!id) return;
      let model = {
        id,
      };
      chatListByID(model).then((list) => {
        this.conversationList = list;
        if (!this.conversationList.length) {
          this.$emit("uNonversationListShow");
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.mainGroup.scrollTop = this.$refs.mainGroup.scrollHeight + 50;
      });
    },
  },
  update() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
  },
};
</script>

<style scoped lang="less">
.contentBox {
  height: 85vh;
  overflow: hidden;
  overflow-y: scroll;
  /* padding: 10% 0; */
}
.bottomTo {
  // background: #000;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  line-height: 50px;
  position: fixed;
  bottom: 40px;
  right: 50px;
}
</style>
