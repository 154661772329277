<template>
  <div class="settingBox">
    <el-form ref="setForm" :model="user" status-icon label-width="80px">
      <h3>个人信息修改</h3>
      <el-form-item prop="name" label="用户名">
        <el-input v-model="oldName" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon
          style="margin-left: -425px"
          @click="updateName"
          >提交</el-button
        >
      </el-form-item>
      <div>
        <el-form-item prop="email" label="邮箱">
          <div class="groupBox">
            <div class="groupBox_ipt">
              <el-input
                v-model="user.email"
                placeholder="请输入邮箱"
                disabled
              ></el-input>
            </div>
            <div class="groupBox_btn">
              <el-button
                v-if="!loadShow"
                @click.prevent="getEmailCode(user.email)"
                >获取验证码</el-button
              >
              <el-button v-else disabled>
                <i class="el-icon-loading"></i>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {{ count }}
                &nbsp;s&nbsp;&nbsp;&nbsp;
              </el-button>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="code" label="验证码">
          <el-input v-model="user.code" placeholder="请输入验证码"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="设置密码">
          <el-input
            v-model="user.password"
            show-password
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword" label="确认密码">
          <el-input
            v-model="user.confirmPassword"
            show-password
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
      </div>

      <el-form-item>
        <el-button
          type="primary"
          icon
          @click="doRegister()"
          style="margin-left: -425px"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getUserInfo } from "@/utils/auth.js";
import { emailCode, userUpdate, forgetUser } from "@/api/user";
export default {
  data() {
    return {
      count: 60,
      timer: null,
      loadShow: false,
      oldName: "",
      user: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        invitationCode: "",
        code: "",
      },
    };
  },
  created() {
    this.user = getUserInfo();
    this.oldName = this.user.name;
    // let userInfo = getUserInfo();
  },
  watch: {
    count(neaVal) {
      if (neaVal < 0) {
        clearInterval(this.timer);
        this.count = 60;
        this.loadShow = false;
      }
    },
  },
  methods: {
    updateName() {
      if (this.user.name == "" || this.user.name == null) {
        this.$message.console.error("用户名字不能为空！！！");
      } else {
        let model = {
          name: this.oldName,
          phoneId: 0,
        };
        userUpdate(model).then(() => {
          this.$message.success("修改成功！！！");
          location.reload();
        });
      }
    },
    getEmailCode() {
      var vm = this;
      vm.loadShow = true;
      vm.count = 60;
      vm.timer = setInterval(() => {
        vm.count--;
      }, 1000);
      emailCode(vm.user.email).then((res) => {
        console.log(res);
        clearInterval(vm.timer);
        vm.count = 60;
        vm.loadShow = false;
      });
    },
    doRegister() {
      if (!this.user.password) {
        this.$message.error("请输入密码！");
        return;
      } else if (!this.user.confirmPassword) {
        this.$message.error("请输入确认密码！");
        return;
      } else if (this.user.confirmPassword != this.user.password) {
        this.$message.error("两次密码不一致！");
        return;
      } else {
        let model = {
          confirmPassword: this.user.confirmPassword,
          email: this.user.email,
          emailCode: this.user.code,
          password: this.user.password,
        };
        forgetUser(model).then(() => {
          this.$message.success("修改成功！！！");
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.settingBox {
  color: #000;
  /deep/.el-input__inner {
    height: 40px;
    font-size: 15px;
    font-weight: bolder;
    color: #000;
  }
}
.groupBox {
  display: flex;
  .groupBox_btn {
    margin-left: 6px;
  }
}
</style>
