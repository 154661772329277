<template>
  <div>
    <div class="addItems">
      <div>
        <button class="btn" @click="addGroupItem">+ 新建会话</button>
      </div>
    </div>
    <div class="itemList">
      <div v-for="(item, index) in list" :key="item.id">
        <div
          class="session"
          @mouseleave="
            defaultIndex = -1;
            editIcon = false;
          "
          @click="findGroup(item.id)"
        >
          <div>
            <i
              class="el-icon-chat-dot-square"
              style="color: white; margin-left: 5px; width: 10%"
            ></i>
          </div>
          <div style="flex: 1; text-align: left; margin-left: 25px">
            <div v-if="defaultIndex != index" class="textName">
              {{ item.groupName }}
            </div>
            <div v-else>
              <el-input v-model="newGroupName"></el-input>
            </div>
          </div>
          <div class="option" v-if="!editIcon">
            <i class="el-icon-edit" @click.stop="editTitle(item, index)"></i>
            &nbsp;
            <i class="el-icon-delete" @click.stop="delTitle(item, index)"></i>
          </div>
          <div class="option" v-else>
            &nbsp;
            <i class="el-icon-circle-check" @click.stop="changeTitle(item)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="setting">
      <transition name="el-fade-in-linear">
        <div class="moreMenu" v-if="moreMenuShow">
          <div class="box-card">
            <div class="itemMenu" @click="selectOption(1)">设置</div>
            <!-- <div class="itemMenu" @click="selectOption(2)">邀请</div> -->
            <div class="itemMenu" @click="selectOption(3)">账户</div>
            <!-- <div class="itemMenu" @click="selectOption(4)">充值</div> -->
            <div class="itemMenu" @click="selectOption(5)">退出</div>
          </div>
        </div>
      </transition>

      <div class="avater" @click="openMene">
        <el-avatar icon="el-icon-user-solid" :size="30"></el-avatar>
      </div>
      <div class="userName" @click="openMene">{{ userInfo.name }}</div>
      <div class="more" @click="openMene"><i class="el-icon-more"></i></div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      custom-class="tipBox"
    >
      <span>此操作将永久删除 《{{ delItem.groupName }}》 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="delGroup">确 定</el-button>
      </span>
    </el-dialog>
    <el-drawer
      title=""
      :visible.sync="drawer"
      :before-close="handleClose"
      :size="size"
    >
      <div>
        <component
          v-bind:is="currentTabComponent"
          ref="tabComponent"
        ></component>
        <!-- handelOnclick -->
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { getUser } from "@/api/user";
import { groupList, groupChange, groupDel } from "@/api/conversation";
import { setUserInfo } from "@/utils/auth";
import account from "@/components/menu/account.vue";
import setting from "@/components/menu/setting.vue";
import invite from "@/components/menu/invite.vue";
import recharge from "@/components/menu/recharge.vue";
import { delToken, delUserInfo } from "@/utils/auth.js";
export default {
  name: "mainPage",
  components: { account, setting, invite, recharge },
  data() {
    return {
      defaultIndex: -1,
      dialogVisible: false,
      delItem: {},
      moreMenuShow: false,
      userInfo: {},
      list: [],
      add: false,
      drawer: false,
      currentTabComponent: null,
      editIcon: false,
      newGroupName: "",
      size: "800px",
    };
  },
  watch: {
    list(list) {
      if (this.add) {
        this.$router.push(
          "/g/" + list[0].id,
          () => {},
          () => {}
        );
        this.add = false;
      }
    },
  },
  created() {
    getUser().then((user) => {
      setUserInfo(user);
      this.userInfo = user;
      this.getGroupList();
    });
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    findGroup(id) {
      this.$router.push(
        "/g/" + id,
        () => {},
        () => {}
      );
      this.$emit("conversation");
    },
    getGroupList() {
      groupList().then((list) => {
        this.list = list;
      });
    },
    addGroupItem() {
      //  this.getGroupList();
      this.add = true;
      this.$router.push(
        "/",
        () => {},
        () => {}
      );
      this.$emit("introduceItemShow");
      // let model = {
      //   value: "新建会话",
      // };
      // addGroup(model).then(() => {
      //   this.getGroupList();
      // });
    },
    editTitle(data, index) {
      this.editIcon = true;
      this.defaultIndex = index;
      this.newGroupName = data.groupName;
    },
    changeTitle(data) {
      let model = {
        id: data.id,
        newName: this.newGroupName,
      };
      groupChange(model).then(() => {
        this.$message.success("会话名修改成功！！！");
        this.defaultIndex = -1;
        this.editIcon = false;
        data.groupName = this.newGroupName;
        this.newGroupName = "";
      });
    },
    delTitle(data) {
      this.delItem = data;
      this.dialogVisible = true;
    },
    delGroup() {
      let model = {
        id: this.delItem.id,
      };
      groupDel(model).then(() => {
        this.getGroupList();
        this.$message.success("会话名删除成功！！！");
        this.$router.push(
          "/",
          () => {},
          () => {}
        );
        this.dialogVisible = false;
      });
    },
    openMene() {
      this.moreMenuShow = !this.moreMenuShow;
    },
    selectOption(key) {
      switch (key) {
        case 1:
          this.currentTabComponent = "setting";
          this.drawer = true;
          this.moreMenuShow = true;
          this.size = "800px";
          break;
        // case 2:
        //   this.currentTabComponent = "invite";
        //   break;
        case 3:
          this.currentTabComponent = "account";
          this.drawer = true;
          this.moreMenuShow = true;
          this.size = "800px";
          this.$nextTick(() => {
            this.$refs.tabComponent.getUserInfo();
          });
          break;
        case 4:
          this.currentTabComponent = "recharge";
          this.drawer = true;
          this.moreMenuShow = true;
          this.size = "100%";
          break;
        case 5:
          this.drawer = false;
          this.$confirm("此操作将退出程序, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              delToken();
              delUserInfo();
              this.$router.push({ path: "/login" });
              this.$router.go(0);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消退出",
              });
            });
          break;
        default:
          this.currentTabComponent = "setting";
          this.drawer = true;
          this.moreMenuShow = true;
          break;
      }
      this.moreMenuShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.addItems {
  margin-bottom: 10px;
}
.itemList {
  height: 85vh;
  text-align: center;
  overflow-y: scroll;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    border-radius: 5px; // 滑块圆角
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc; // 滑块颜色
    border-radius: 5px; // 滑块圆角
  }
  .textName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
  }
}
.setting {
  width: 90%;
  margin: 0 auto;
  display: flex;
  position: relative;
  padding: 10px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  &:hover {
    border-radius: 4px;
    background-color: rgba(114, 113, 113, 0.4);
  }
  .avater {
    cursor: pointer;
  }
  .userName {
    flex: 1;
    padding: 4px 0px 4px 8px;
    text-align: start;
    cursor: pointer;
  }
  .more {
    padding: 6px 8px 6px 0px;
    cursor: pointer;
  }
  .moreMenu {
    position: absolute;
    width: 100%;
    bottom: 65px;
    .box-card {
      background-color: rgb(0, 0, 0);
      color: white;
      border: none;
      border-radius: 8px;
      padding: 10px 0px;
      .itemMenu {
        cursor: pointer;
        padding: 10px 0px;
        &:hover {
          border-radius: 4px;
          background-color: rgba(114, 113, 113, 0.4);
        }
      }
    }
  }
}

/deep/.el-dialog {
  margin-top: 15vh;
  width: 25% !important;
  border-radius: 12px;
  height: 208px;
}
.btn {
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 80%;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  margin-top: 10px;
  padding: 10px;
}
.btn:hover {
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
}

.session {
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  padding: 16px 0px;
  width: 90%;
}
.session:hover {
  border-radius: 4px;
  background-color: rgba(114, 113, 113, 0.4);
}
.session:hover .option {
  display: block;
}
.option {
  padding-right: 15px;
  display: none;
}
/deep/.el-input__inner {
  height: 21px;
  font-size: 15px;
  font-weight: bolder;
  color: #000;
}
</style>
