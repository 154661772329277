<template>
  <div class="introduce">
    <div class="title">ChatGPT</div>
    <div class="infoBox">
      <div class="item">
        <div>例子</div>
        <div class="info">用简单的术语解释量子计算</div>
        <div class="info">对一个10岁的生日有什么创意吗</div>
        <div class="info">如何在 Javascript 中发出 HTTP 请求</div>
      </div>
      <div class="item">
        <div>能力</div>
        <div class="info">记住用户之前在对话中所说的话</div>
        <div class="info">允许用户提供后续更正</div>
        <div class="info">接受过拒绝不当请求的培训</div>
      </div>
      <div class="item">
        <div>局限性</div>
        <div class="info">可能偶尔生成不正确的信息</div>
        <div class="info">可能偶尔产生有害说明或有偏见的内容</div>
        <div class="info">对2021年后的世界和事件的了解有限</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.introduce {
  height: 45vh;
  padding: 10% 0;
}
.title {
  font-size: 40px;
  font-weight: bolder;
  padding-bottom: 50px;
}
.infoBox {
  text-align: center;
  margin: 0 auto;
  width: 70%;
  display: flex;
  .item {
    flex: 1;
    .info {
      margin: 10px;
      background-color: rgba(247, 247, 248, 1);
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        background-color: rgba(217, 217, 227, 1);
      }
    }
  }
}
</style>
