<template>
  <div style="color: red">邀请代码</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
