<template>
  <div>
    <div class="cardBox">
      <div class="cardItem">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>套餐A</span>
          </div>
          <div class="item moy">￥10.00</div>
          <div class="item btn">
            <el-button @click="buy">购买</el-button>
          </div>
          <div class="item text">
            <div>
              够买套餐将会获得额外<span style="color: blue">5%</span>的铜币
            </div>
            <div style="padding-top: 10px">
              ￥1=10500个铜币，与AI会话消耗铜币
            </div>
          </div>
        </el-card>
      </div>
      <div class="cardItem">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>套餐B</span>
          </div>
          <div class="item moy">￥30.00</div>
          <div class="item btn">
            <el-button @click="buy">购买</el-button>
          </div>
          <div class="item text">
            <div>
              够买套餐将会获得额外<span style="color: green">20%</span>的铜币
            </div>
            <div style="padding-top: 10px">
              ￥1=12000个铜币，与AI会话消耗铜币
            </div>
          </div>
        </el-card>
      </div>
      <div class="cardItem">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span @click="buy">套餐C</span>
          </div>
          <div class="item moy">￥100.00</div>
          <div class="item btn">
            <el-button @click="buy">购买</el-button>
          </div>
          <div class="item text">
            <div>
              够买套餐将会获得额外<span style="color: red">85%</span>的铜币
            </div>
            <div style="padding-top: 10px">
              会被永久标记为VIP,每次小额充值将会有额外铜币，￥1=18500个铜币，,与AI会话消耗铜币
            </div>
          </div>
        </el-card>
      </div>
      <div class="cardItem">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>自定义</span>
          </div>
          <div class="item moy inp">
            <el-input type="number" :min="0" v-model="money"></el-input>
          </div>
          <div class="item btn">
            <el-button @click="buy">购买</el-button>
          </div>
          <div class="item text">
            <div>够买套餐将会获得对等金额的的铜币</div>
            <div style="padding-top: 10px">
              ￥1=10000个铜币，与AI会话消耗铜币
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      money: 0,
    };
  },
  methods: {
    buy() {
      const h = this.$createElement;
      this.$notify({
        title: "提示",
        message: h(
          "i",
          { style: "color: green" },
          "内测阶段，该功能暂未开放，详情咨询请联系客服！"
        ),
        duration: 10000,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.cardBox {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  .cardItem {
    padding: 120px 20px;
    .el-card {
      width: 350px;
    }
  }
}
.text {
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
}
.inp {
  color: black;
  /deep/.el-input__inner {
    height: 40px;
    width: 30%;
    font-size: 15px;
    font-weight: bolder;
    color: #000;
  }
}
.item {
  padding-top: 20px;
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}
</style>
