<template>
  <div class="mainBox">
    <introduceItem v-if="introduceItemShow" />
    <contentItem
      @uNonversationListShow="uNonversationListShow"
      @closeOnload="closeOnload"
      ref="contentItem"
      v-show="!introduceItemShow"
    />
    <div class="inputBox">
      <div style="position: relative">
        <el-input
          type="textarea"
          v-prevent-enter
          :autosize="{ minRows: 1, maxRows: 4 }"
          placeholder="(Shift+Enter换行)...."
          v-model="question"
          @keydown.enter.native="handleEnterKey"
        >
        </el-input>
        <div class="updateIcon" @click="sendQuestion" v-if="!load">
          <i class="el-icon-upload" style="font-size: 26px"></i>
        </div>
        <div class="updateIcon" v-else>
          <i class="el-icon-loading" style="font-size: 26px"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import contentItem from "./content.vue";
import introduceItem from "./introduce.vue";
import { chatSee } from "@/api/conversation";
var shiftKey = false;
export default {
  name: "indexPage",
  components: { contentItem, introduceItem },

  data() {
    return {
      question: "",
      load: false,
      introduceItemShow: true,
      conversationId: 0,
      conversationList: [],
      shiftKey: false,
    };
  },
  directives: {
    "prevent-enter": {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "Enter") {
            if (!shiftKey) {
              event.preventDefault();
            }
          } else if (event.key === "Shift") {
            shiftKey = true;
          }
        });
        el.addEventListener("keyup", (event) => {
          if (event.key === "Shift") {
            shiftKey = false;
            event.preventDefault();
          }
        });
      },
    },
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) {
        this.conversationId = this.$route.params.id;
        this.conversationListShow();
      } else {
        this.conversationId = 0;
        this.uNonversationListShow();
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.conversationId = this.$route.params.id;
      this.conversationListShow();
    } else {
      this.conversationId = 0;
      this.uNonversationListShow();
    }
  },
  methods: {
    handleEnterKey() {
      if (!shiftKey) {
        this.sendQuestion();
      }
    },
    uNonversationListShow() {
      this.introduceItemShow = true;
    },
    conversationListShow() {
      this.introduceItemShow = false;
    },
    closeOnload() {
      this.load = false;
    },
    sendQuestion() {
      this.$refs.contentItem.addItem(this.question);
      if (!this.question) {
        this.load = false;
      } else {
        this.load = true;
      }
      this.$refs.contentItem.setupSSE();
      var model = {
        content: this.question,
        groupId: this.conversationId,
        stream: true,
      };
      chatSee(model).then((res) => {
        this.question = "";
        // this.load = false;
        if (!this.conversationId) {
          this.$emit("getGroupList");
          this.$router.push(
            "/g/" + res.groupId,
            () => {},
            () => {}
          );
        }

        this.introduceItemShow = false;
      });
    },
  },
};
</script>

<style scoped lang="less">
.mainBox {
  position: relative;
  .inputBox {
    width: 70%;
    position: fixed;
    left: 300px;
    right: 0;
    margin: 0 auto;
    bottom: 50px;
    .updateIcon {
      cursor: pointer;
      position: absolute;
      right: 14px;
      top: 5px;
    }
  }
  /deep/.el-textarea__inner {
    width: 100%;
    outline: none;
    border-radius: 6px;
    font-size: 16px;
    resize: none;
    color: inherit;
    padding-right: 40px;
  }
}
</style>
