<template>
  <div>
    <div class="content">
      <div class="cs">
        <div class="markdown-content" v-html="renderedMarkdown"></div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import Prism from "prismjs";
import "prismjs/themes/prism-okaidia.css"; // 背景颜色
import "prismjs/components/prism-markup"; // 导入标记语言支持，如HTML/XML
import "prismjs/components/prism-javascript"; // 导入JavaScript语言支持
import "prismjs/components/prism-css"; // 导入CSS语言支持
import "prismjs/components/prism-java"; // 导入Java语言支持
import "prismjs/components/prism-clike"; // 用于通用的C-like语言支持
import "prismjs/components/prism-bash"; // 用于Bash/Shell语言支持
import "prismjs/components/prism-python"; // 用于Python语言支持
import "prismjs/components/prism-go"; // 用于Python语言支持
export default {
  props: {
    code: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      renderedMarkdown: "",
    };
  },
  watch: {
    code() {
      this.renderMarkdown();
      this.highlightCode();
    },
  },
  mounted() {
    this.renderMarkdown();
    this.highlightCode();
  },
  methods: {
    renderMarkdown() {
      const md = new MarkdownIt();
      if (this.code) {
        this.renderedMarkdown = md.render(this.code);
      }
    },
    highlightCode() {
      this.$nextTick(() => {
        const codeBlocks = this.$el.querySelectorAll("pre code");
        codeBlocks.forEach((codeBlock) => {
          Prism.highlightElement(codeBlock);
        });
      });
    },
  },
};
</script>
<style scoped lang="less">
.content {
  text-align: start;
  margin: 0 auto;
  width: 90%;
  padding: 10px 0px;
  // border-color: rgba(0, 0, 0, 0.1);
  border-top: solid 1px rgba(0, 0, 0, 0.1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: rgba(217, 217, 217, 0.3);
}
.cs {
  margin: 0 auto;
  width: 90%;
}

.markdown-content {
  text-align: start;
  background-color: #f2f2f2;
  padding: 10px;
}
</style>
