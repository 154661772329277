<template>
  <div>
    <div class="contentTxt">
      <div class="contentInfo">{{ text }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    text: {
      type: String,
      default: () => {
        return "SB";
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    // getLanguageClass(code) {
    //   const language = code.match(/```(.*)\n/)[1]; // 提取代码块中的语言类型
    //   return `language-${language}`;
    // },
  },
};
</script>

<style>
.contentTxt {
  text-align: start;
  padding: 10px 0px;
}
.contentInfo {
  margin: 0 auto;
  width: 90%;
}
</style>
