<template>
  <div class="account">
    <div class="accountBox">
      <div class="brderBottom">
        <div style="font-size: 24px; padding-bottom: 20px">分享赚时长</div>
        <!-- <div class="title" style="font-size: 19px">
          <span>您授权到期时间：2023年5月5日0:0:0 - </span>
          <span style="color: #f0a020">剩余-10天</span>
        </div> -->
        <div class="title">
          您已成功邀请【0】人。 每邀请1人注册，可增加10000铜币， 一直分享，<span
            style="color: #18a058"
            >一直免费使用。</span
          >
        </div>
        <div class="title">
          <div class="groupBox">
            <div>您的邀请链接为:</div>
            <div class="groupBox_ipt">
              <el-input
                v-model="this.allUrl"
                style="color: red"
                disabled
              ></el-input>
            </div>
            <div class="groupBox_btn">
              <el-button @click="copy">复制链接</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="brderBottom">
        <div style="font-size: 24px; padding: 20px 0px">账户余额</div>
        <div class="title" style="font-size: 19px">
          铜币剩余：<span style="color: #18a058">{{ userInfo.tokens }}</span>
          个
        </div>
      </div>
      <div>
        <div style="font-size: 24px; padding: 20px 0px">联系客服</div>
        <div class="imgBox">
          <div class="img">
            <img src="../../assets/images/f.jpg" />
            请使用QQ扫描
          </div>
          <div class="img">
            <img src="../../assets/images/qun.png" />
            请使用QQ扫描
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setUserInfo, getUserInfo } from "@/utils/auth";
import { getUser } from "@/api/user";
export default {
  data() {
    return {
      inviteCode: "",
      userInfo: {},
      url: "http://chat.wwsix.cn/r",
      url1: "@/assets/images/f.jpg",
    };
  },
  created() {
    this.userInfo = getUserInfo();
    this.inviteCode = this.userInfo.invitationCode;
    this.allUrl = this.url + "/" + this.inviteCode;
  },
  methods: {
    copy() {
      var input = document.createElement("input");
      input.value = this.url + "/" + this.inviteCode;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      this.$message.success("复制成功");
    },
    getUserInfo() {
      getUser().then((user) => {
        setUserInfo(user);
        this.userInfo = user;
        this.inviteCode = this.userInfo.invitationCode;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.account {
  color: black;
  /deep/.el-input__inner {
    height: 40px;
    font-size: 15px;
    font-weight: bolder;
    color: #000;
  }
}
.title {
  padding: 10px 0px;
}
.groupBox {
  display: flex;
  align-items: center;
  .groupBox_ipt {
    flex: 1;
  }
  .groupBox_btn {
    margin-left: 6px;
  }
}
.accountBox {
  text-align: start;
  padding: 20px;
}
.brderBottom {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(147, 141, 141, 0.5);
}
.imgBox {
  display: flex;
  align-items: center;
  justify-content: center;
  .img {
    margin: 10px;
    // background: #000;
    text-align: center;
    width: 200px;
    height: 200px;
    img {
      width: 200px;
      height: 200px;
    }
  }
  .qrcode1 {
    background: url("../../assets/images/f.jpg") no-repeat;
    background-size: cover;
  }
}
</style>
