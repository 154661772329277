import request from '@/plugins/request';
export function addGroup (data) {
  return request({
    url: '/endpoint/v1/group/add',
    method: 'post',
    data
  });
}
export function groupList (data) {
  return request({
    url: '/endpoint/v1/group/list',
    method: 'post',
    data
  });
}
export function groupChange (data) {
  return request({
    url: '/endpoint/v1/group/change',
    method: 'post',
    data
  });
}
export function groupDel (data) {
  return request({
    url: '/endpoint/v1/group/del',
    method: 'post',
    data
  });
}
export function chatListByID (data) {
  return request({
    url: '/endpoint/v1/chat/list',
    method: 'post',
    data
  });
}
export function chatSee (data) {
  return request({
    url: '/endpoint/v1/chat/see',
    method: 'post',
    data
  });
}


